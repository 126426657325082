* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:hover,
*:active {
  outline: none !important;
}

body {
  width: 100vw;
  height: 100vh;
  /* overflow-x: hidden; */
}

.centered-image-container {
  display: grid;
  place-items: center;
}

.centered-image {
  max-width: 100%;
  max-height: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  background: transparent;
  color: #336a86ff;
  font-weight: 800;
}

.swiper-button-next::after {
  font-size: 15px !important;
}
.swiper-button-prev::after {
  font-size: 15px !important;
}
.MuiPaper-elevation2,
.MuiPaper-elevation3,
.MuiPaper-elevation4,
.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  /* box-shadow: none !important; */
}
