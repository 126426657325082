p {
  margin: 0px;
}

.message {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 0.5rem;
}
.yourMessage {
  flex-direction: row-reverse;
}
.flexend {
  display: flex;
  justify-content: flex-end;
}
.hidden {
  display: none;
}
.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.userAvatar > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.messageInfo {
  /* margin should in case of other user  */
  margin-top: 10px;
  max-width: 40%;
  padding: 10px 10px 20px;
  background-color: #dedede;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  position: relative;
}

.meArc {
  border-bottom-right-radius: 0px;
}
.otherArc {
  border-top-left-radius: 0px;
}
.userName {
  display: flex;
  justify-content: space-between;
}

.verticalIcon {
  font-size: 0.8rem;
  opacity: 0;
  cursor: pointer;
  position: relative;
}
.messageInfo:hover .verticalIcon {
  opacity: 1;
}

.deleteOrEdit {
  position: absolute;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  top: 10px;
  right: 15px;
  z-index: 999;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.2rem;
  opacity: 0;
  border-radius: 4px;
}
.verticalIcon:hover,
.deleteOrEdit {
  opacity: 1;
}
.deleteOrEdit > span {
  font-size: 1rem;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  font-weight: 500;
}
.deleteOrEdit > span:hover {
  width: 100%;
  height: 100%;
  background-color: #d3d3d3;
}
.userName > button {
  outline: none;
  padding: 0px;
  border: none;
}
.userName > p {
  margin: 0px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #0741e2;
}

.textOrDoc {
  display: flex;
  align-items: start;
  margin: 5px 0px;
}
.textOrDoc .messageImage {
  max-width: 300px;
  height: 200px;
  border-radius: 12px;
}
.messageImage > a > img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.messageDocs {
  /* margin: 5px 0px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 12px;
  padding: 5px 5px 10px;
}

.docsInfo {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  gap: 0.5rem;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.docsImage {
  max-width: 300px;
  height: 150px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.docsImage > img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.docsName {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  padding: 0px 10px;
  text-decoration: none;
  color: black;
}
.docsName:hover {
  text-decoration: none;
  color: black;
  cursor: auto;
}
.docsIcon > svg {
  width: 30px;
  height: 30px;
}
.docsName > p {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show before ellipsis */
  -webkit-box-orient: vertical;
  font-size: 0.97rem;
  margin: 0;
  font-weight: 500;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docsButtons {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px;
}
.docsButtons > a {
  padding: 5px 10px;
  flex: 1 1 0%;
  outline: none;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 6px;
  background-color: #fafafa;
  text-decoration: none;
  color: black;
  text-align: center;
  word-break: keep-all;
}

.docsButtons > a:hover {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  text-decoration: none;
  color: black;
}
.textOrDoc > p {
  font-size: 1rem;
  margin: 0px;
  font-weight: 500;
  word-break: break-all;
  overflow-x: hidden;
  white-space: wrap;
  flex: 1 1 0%;
  user-select: text;
}

.time {
  position: absolute;
  /* flex: 1 1 0%; */
  display: flex;
  width: 100%;
  justify-content: end;
  right: 10px;
  bottom: 0px;
}
.time > span {
  align-self: flex-end;
  font-size: 0.8rem;
}

.singleEmoji {
  flex: 1 1 0%;
  user-select: text;
  font-size: 1.8cm;
  text-align: center;
}
.doubleEmoji {
  flex: 1 1 0%;
  user-select: text;
  font-size: 1.5rem;
  /* text-align: center; */
}
@media (max-width: 768px) {
  .messageInfo {
    max-width: 50%;
  }
}
@media (max-width: 600px) {
  .messageInfo {
    max-width: 70%;
  }
  .docsButtons {
    display: none;
  }
  .messageDocs {
    box-shadow: none;
  }
}
