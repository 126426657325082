.loading-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.973);
  height: 100vh;
  width: 100%;
  z-index: 10;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}
