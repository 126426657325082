.mySwiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  justify-content: center;
  align-items: center;
}

.slideImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.video {
  max-width: 100%;
  height: auto;
}
/* .video::-webkit-media-controls {
  display: none;
}

.video::-webkit-media-controls-enclosure {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.video::-webkit-media-controls-mute-button,
.video::-webkit-media-controls-volume-slider {
  display: inline-flex;
} */
