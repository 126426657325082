.button {
  margin-top: 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  width: 100%;
  text-transform: capitalize;

  border: transparent;
  border-radius: 0.5rem;

  padding: 0.3rem 1rem;
}

.button > span {
  font-size: 1rem;
  font-weight: 600;
  color: white;
}

.button > svg {
  font-size: 1.5rem;
  color: white;
}
