.chatHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user {
  display: flex;
  flex: 1 1 0%;
  gap: 0.6rem;
}
.ArrowBack {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.ArrowBack > svg {
  display: none;
}
.ArrowBack .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.avatar > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.userInfo > p {
  font-size: 1.2rem;
  margin: 0px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 20ch;
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}
.chatSettings {
  position: relative;
  cursor: pointer;
}

.chatSettingsPopOver {
  z-index: 9999;
  min-width: 250px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 10px;
  right: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.chatSettingsPopOver > h3 {
  margin: 0px;
  font-size: 1.1rem;
  padding: 5px 10px;
}
.chatSettingsPopOver > button {
  outline: none;
  border: none;
  background-color: #dfdede;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #dfdede;
}
.chatSettingsPopOver > button:hover {
  background-color: white;
  border: 1px solid #dfdede;
}
@media (max-width: 996px) {
  .ArrowBack > svg {
    display: block;
    position: relative;
    left: -10px;
  }
}
