.login {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #F8F6F4;
}

@media only screen and (max-width: 600px) {
  .login {
    padding: 20px;
    /* Add padding for smaller screens */
  }

  .login img {
    width: 250px;
    height: 190px;
  }

  .login .container {
    width: 100%;
    /* Make the container full width on smaller screens */
  }

  .login .MuiPaper-rounded {
    width: 370px;
  }

  .login .container .MuiBox-root-1 {
    width: 370px;
  }
}