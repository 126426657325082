.sidebar {
  width: 380px;
  height: 100%;
  /* border: 1px solid cyan; */
  display: flex;
  flex-direction: column;
  /* bg-new added */
  background-color: #dedede;
  gap: 1rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* border-right: 1px solid gray; */
}

.sidebarFixedContainer {
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.Heading {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgb(226, 232, 240);
}
.sidebarFixedContainer h2 {
  font-size: 1.8rem;
  font-weight: 400;
}
.InputContainer {
  padding-top: 10px;
  width: 100%;
  position: relative;
}

.InputContainer input {
  position: relative;
  width: 100%;
  padding-left: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  outline: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 22px;
}

input::placeholder {
  color: grey;
  text-transform: capitalize;
}

.InputContainer .faSearch {
  position: absolute;
  top: 40%;
  font-size: 1.2rem;
  font-weight: 400;

  /* transform: translateY(50%, -50%); */
  left: 10px;
  z-index: 200;
}

.scrollableContainer {
  flex: 1 1 0%;

  overflow-y: scroll;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 1ms ease;
  scrollbar-color: transparent transparent;
}
.scrollableContainer::-webkit-scrollbar {
  width: 6px;
}

.scrollableContainer::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 22px;
}

.scrollableContainer:hover::-webkit-scrollbar-thumb {
  background-color: #888;
}
.scrollableContainer:hover::-webkit-scrollbar-track {
  background-color: #dedede;
}

.SidebarItem {
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0px;
  border-radius: 8px;
}

.SidebarItemActive,
.SidebarItem:hover {
  background-color: #fafafa;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.SidebarItem:hover {
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}
.SidebarItem .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.avatar > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.SidebarItem .SidebarRight {
  flex: 1 1 0%;
  /* border: 1px solid cyan; */
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.SidebarRightTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SidebarRightTop > p {
  font-size: 1.2rem;
  margin: 0px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 15ch;
  overflow-x: hidden;
  white-space: nowrap;
  flex: 1 1 0%;
}
.SidebarRightTop > span {
  /* width: 30px; */
  font-weight: 300;
  /* font-style: italic; */
}
.SidebarRightBottom {
  width: 100%;
  display: flex;
  align-items: center;
}
.SidebarRightBottomLeft {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.SidebarRightBottomLeft > p {
  margin: 0px;
  font-size: 1rem;

  font-weight: 400;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  flex: 1 1 0%;
  width: 100px;
}
.SidebarRightBottomLeft > span {
  margin: 0px;
  font-size: 1rem;
  font-weight: 400;
  text-overflow: ellipsis;
  max-width: 15ch;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: 600;
  text-transform: lowercase;
}
.SidebarRightBottomLeft > svg {
  font-size: 20px;
}
@media (max-width: 996px) {
  .sidebar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .scrollableContainer::-webkit-scrollbar {
    width: 0px;
  }
}
