.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-text {
  font-size: 10px;
}

@media (max-width: 600px) {

  .header-mobile-part-1 {
    display: none;
  }

  .header-part-2 {
    display: none;
  }

  .header-mobile header {
    top: 55px;
  }

  .header-mobile-part-1 image {
    height: 50px;
    width: 80px;
  }

  .header-part-3 {
    margin-left: 110px;
  }

}

#header-name {
  font-family: "Dancing Script", cursive;
  font-size: 32px;
  color: inherit;
}

.header-part-1 {
  flex: 3;
}

.header-part-2,
.header-mobile-part-2 {
  flex: 6;
}

.header-part-3 {
  flex: 3;
}

.header>.header-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header>.header-part-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-feedback {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  display: grid;
  place-items: center;
}