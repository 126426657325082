.NameContainer,
.FormButtons {
  display: flex;
  gap: 1.5rem;
}

.PreviousButton {
  flex-direction: row-reverse;
}

.PreviousIcon {
  order: 0;
}

.usernameIcon {
  font-weight: 700;
  font-size: 1.3rem;
}

@media (max-width: 767px) {
  .NameContainer,
  .FormButtons {
    flex-direction: column;
    gap: 0px;
  }
}
