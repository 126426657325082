.chatAction {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  position: relative;
}
.popover {
  position: absolute;
  bottom: 50px;
  transform: scale(0);
  transform-origin: top center;
  transition: transform 10s ease-out;
  display: none;
}
.popovershow {
  display: block;
  transform: scale(1);
}

.popover .documentUploader {
  width: 200px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.documentUploader > h3 {
  margin: 0px;
  font-size: 1.1rem;
  padding: 5px 10px;
}

.documentUploader > button {
  outline: none;
  border: none;
  background-color: #dfdede;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 5px 10px;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #dfdede;
}
.documentUploader > button:hover {
  background-color: white;
  border: 1px solid #dfdede;
}
.icon {
  outline: none;
  border: none;
  background: transparent;
  padding: 5px;
  border-radius: 4px;
}
.icon:hover {
  background-color: #fefffe;
}
.icon > svg {
  font-size: 1.7rem;
}

.inputField {
  flex: 1 1 0%;
}

.inputField > textarea {
  outline: none;
  width: 100%;
  max-height: 4.5em;
  resize: none;
  height: auto;
  border: none;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 4px 20px;
  font-size: 1.1rem;
  scroll-behavior: smooth;
}
.inputField > textarea::-webkit-scrollbar {
  width: 0px;
  display: none !important;
}
.inputField > textarea::placeholder {
  color: grey;
  text-align: start;
}
