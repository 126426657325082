@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Work+Sans:wght@300;400;500;600&display=swap");

.login {
  min-height: 100vh;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f6f4;
  font-family: "Work sans";
}

.wrapper {
  position: relative;
}

.image_1 {
  position: absolute;
  bottom: -12px;
  left: -191px;
  z-index: 99;
}

.image_2 {
  position: absolute;
  bottom: 0;
  right: -129px;
}

.formContainer {
  width: 100%;
  min-width: 450px;
  /* Min height can be creat a problem i large devices */
  min-height: 50vh;
  position: relative;
  z-index: 9;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 24px 48px;
  border-radius: 1rem;
  padding: 2.375rem 2rem 3rem;
}

.logo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.logo > img {
  width: 100%;
  height: 80px;
  align-self: start;
  object-fit: contain;
}
.signinInfo {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

.signinInfo > h3 {
  font-size: 1.3rem;
  color: black;
  font-weight: 700;
  margin: 0px;
}
.signinInfo > p {
  color: gray;
  font-size: 0.9rem;
}
.Formik {
  width: 100%;
}
.FormikForm {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.DontHaveAccount {
  width: 100%;
  margin-top: 2rem;
  color: gray;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}
.DontHaveAccount > span {
  color: #000;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}
.usernameIcon {
  font-weight: 700;
  font-size: 1.3rem;
}
.spinner {
  width: 20px;
  height: 20px;
  margin-left: 1rem;
  border-radius: 50%;
  border: 3px solid;
  order: 0;
  border-color: #dbdcef;
  border-right-color: black;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 991px) {
  .inner {
    width: 400px;
    left: 4%;
  }
}
@media (max-width: 767px) {
  .wrapper {
    width: 100%;
    left: 0;
  }

  .image_1,
  .image_2 {
    display: none;
  }

  .formContainer {
    min-width: 100%;
    width: 100%;
    padding: 25px;
    /* box-shadow: none; */
  }

  .login {
    background: none;
  }
}
