@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Work+Sans:wght@300;400;500;600&display=swap");

.chatContainer {
  /* overflow-x: hidden; */
  user-select: none;
  font-family: "Work sans";
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: white;
  max-height: 100vh;
  height: 100%;
  /* height: 98%; */
  display: flex;

  width: calc(100%-20px);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.chatIdPage {
  height: 100%;
  flex: 1 1 0%;
}
.isActiveChat {
  opacity: 0;
  transition: all 0.5s ease;
  width: 0px;
}
.NoChatSelected {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  background-image: url("https://www.shutterstock.com/image-vector/vector-seamless-mobile-apps-pattern-600nw-283356059.jpg");
}

.emptymessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dedede;
  border-radius: 12px;
  padding: 10px;
}
.emptymessage .applogo {
  width: 300px;
  height: 100px;
}
.applogo > img {
  width: 100%;
  height: 100%;
}
.emptymessage > p {
  font-size: 1.5rem;
  text-align: center;
  width: 60%;
}

@media (max-width: 996px) {
}
@media (max-width: 600px) {
  .chatContainer {
    margin: 0px;
    margin-top: 65px;
    width: 100%;
    height: 100%;
  }
}
