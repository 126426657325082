.CardContainer {
  user-select: none;
  min-width: 200px;
  width: 30%;
  height: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
}

.profilePicture {
  cursor: pointer;
  width: 100px;
  height: 100px;
  user-select: none;
  border-radius: 50%;
}
.profilePicture > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  border-radius: 50%;
}

.UserDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UserDetails > span:nth-child(1) {
  font-size: 1rem;
  width: 100%;
  color: #0a0a0a;
  font-weight: 500;
  text-align: center;
}

.UserDetails > span:nth-child(2) {
  max-width: 200px;
  font-size: 0.9rem;
  color: #03ae9d;
  cursor: pointer;
  overflow-x: hidden;
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
}
.academicDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.academicDetails > span:nth-child(1) {
  font-size: 0.8rem;
  text-align: center;
  white-space: wrap;
  width: 100%;
  text-transform: capitalize;
  color: #0a0a0a;
  font-weight: 500;
}

.academicDetails > span:nth-child(2) {
  font-size: 0.8rem;
  text-align: center;
  white-space: wrap;
  width: 100%;
  color: #209920;
  display: none;
  font-weight: 500;
}

.ActionButtons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}
.ActionButtons > button {
  border: none;
  border-radius: 5px;
  /* background: #ff6961; */
  background: #000000;
  width: 100%;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  color: white;
  outline: none;
}

.spinner {
  width: 20px;
  height: 20px;
  margin-left: 1rem;
  border-radius: 50%;
  border: 3px solid;
  order: 0;
  border-color: #dbdcef;
  border-right-color: black;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 500px) {
  .CardContainer {
    width: 100%;
  }
}
