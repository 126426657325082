.iframeContainer {
  width: 100%;
  overflow-x: hidden;
  height: calc(100vh - 80px);
  margin-top: 80px;
  padding: 20px, 40px;
}

.iframe {
  width: 100%;
  height: 100%;
}
