.chatIdPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid #dedede; */
  border-left: 1px solid #cbcaca;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chatHeader {
  width: 100%;
  padding: 5px 15px;
  background-color: #dedede;
  /* border: 1px solid red; */
  border-bottom: 1px solid rgb(131, 130, 130);
}

.chatMessages {
  flex: 1 1 0%;
  width: 100%;
  overflow-y: scroll;
}
.chatMessages::-webkit-scrollbar {
  display: none;
}
.chatAction {
  width: 100%;
  background-color: #dedede;
  padding: 8px 20px;
  /* border: 1px solid red; */
}
