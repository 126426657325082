.uploadImageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 10px;
}

.uploadImageContainer > div {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-start;
}

.uploadImageContainer > div > span {
  font-size: 1rem;
  font-weight: 600;
}

.Imageurl {
  width: 100%;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: #f3f3f3;
}

.Imageurl::placeholder {
  color: grey;
}

.uploadImageContainer .linebreak {
  display: flex;
  gap: 0.2rem;
  flex-direction: row;
  align-items: center;
}

.linebreak > span {
  width: 50%;
  height: 0.5px;
  background: grey;
}

.linebreak > p {
  flex: 1 1 0;
  margin-top: 10px;
  font-weight: 600;
}
.addFromDevice {
  position: relative;
  border: 1px dashed black;
  border-radius: 12px;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
}
.addFromDevice > svg {
  font-size: 4rem;
  color: #37b1ee;
}
.addFromDevice > input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.uploadItemImg {
  width: 100%;
  height: 250px;
  position: relative;
  border-radius: 12px;
}
.uploadItemImg > img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: contain;
}
.uploadItemImg > svg,
.docsInfo > svg {
  position: absolute;
  opacity: 0;
  filter: invert(100%);
  font-size: 2rem;
  right: 0px;
  cursor: pointer;
}
.uploadItemImg:hover > svg,
.docsInfo > svg {
  opacity: 1;
}
.uploadFileProgress {
  width: 100%;
}

.uploadFileProgress > span {
  margin: 0 auto;
}

.uploadImageContainer .docsInfo {
  display: flex;
  width: 70%;
  flex-flow: column;
  gap: 0.5rem;
  position: relative;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.docsInfo .docsImage {
  width: 100%;
  height: 150px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.docsImage > img {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
}
.docsName {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  padding: 15px 10px;
  text-decoration: none;
  color: black;
}
.docsName:hover {
  text-decoration: none;
  color: black;
  cursor: auto;
}
.docsIcon > svg {
  width: 30px;
  height: 30px;
}
.docsName > p {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show before ellipsis */
  -webkit-box-orient: vertical;
  font-size: 0.97rem;
  margin: 0;
  font-weight: 500;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loader {
  width: 20px;
  height: 20px;
  border: 5px solid #37b1ee;
  border-bottom-color: #000;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .uploadImageContainer > div {
    width: 100%;
  }
  .ModalContainer {
    max-height: 600px;
    width: 90%;
  }
}
