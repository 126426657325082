.chatBackground {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 5px 15px 15px 10px;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  scrollbar-color: transparent transparent;
}

.chatBackground::-webkit-scrollbar {
  width: 6px;
}
.chatBackground::-webkit-scrollbar-thumb {
  /* background-color: #fff; */
  border-radius: 22px;
}
.chatBackground:hover::-webkit-scrollbar-thumb {
  background-color: #888;
}
.chatBackground:hover::-webkit-scrollbar-track {
  background-color: #dedede;
}
