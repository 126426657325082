.FormInputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
}

.FormInputContainer > label {
  margin: 0px;
  color: black;
  font-weight: 500;
  font-size: 1rem;
  text-transform: capitalize;
}

.FormInput {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.FormInput > input {
  margin: 0px;
  width: 100%;
  font-weight: 400;

  color: black;
  line-height: 1.25;
  border-radius: 0.375rem;
  padding: 0.4rem 2.5rem 0.4rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.FormInput > .inputIconSecond {
  position: absolute;
  cursor: pointer;
  right: 8px;
}
.inputIconSecond > svg {
  font-size: 1.5rem;
}
.FormInputContainer > p {
  margin: 0px;
  color: red;
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: lowercase;
  width: 300px;
  text-wrap: wrap;
  word-break: break-all;
}
