.isNewPostAvailable {
  position: absolute;
  padding: 5px 10px;
  background: #32cd32;
  color: #fff;
  font-weight: 600;
  border-radius: 25px;
  cursor: pointer;
  top: -150px;
  left: 40%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

@media (max-width: 768px) {
  .isNewPostAvailable {
    top: -750px;
    left: 40%;
  }
}

@media (max-width: 500px) {
  .isNewPostAvailable {
    top: -800px;
    left: 40%;
  }
}
