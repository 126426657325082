.home {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  height: 100vh;
  overflow-y: auto;
}

#demo {
  margin-top: 20px;
}

#home-center-wrapper .notice-card {
  display: none;
}

.container {
  padding: 0 15px;
}

.notice-card {
  margin-bottom: 25px;
}

.job-modal .modal-content {
  top: 30px;
}

.job-card .MuiCardContent-root {
  padding-bottom: 16px !important;
}

.poll-card {
  margin-bottom: 25px;
}

#home-center-wrapper .poll-card {
  display: none;
}

#home-center-wrapper .demo {
  display: none;
}

#create-post-inputfield {
  border: 2px solid rgba(0, 0, 0, 0.426);
  padding: 8px 10px;
  border-radius: 20px;
}

.home-right-bar {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.home-right-bar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

#home-center-wrapper {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

#home-center-wrapper::-webkit-scrollbar {
  display: none;
}

.friends-tab {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.friends-tab::-webkit-scrollbar {
  display: none;
}

.bookmark-wrapper::-webkit-scrollbar {
  display: none;
}

iframe-container {
  margin-top: 54px;
}

@media (max-width: 600px) {
  #home-center-wrapper {
    padding: 0 15px;
  }

  #demo {
    display: none;
  }

  .home-right-bar,
  .friends-tab,
  .bookmark-wrapper {
    padding: 0 15px;
  }

  .job-modal .modal-content {
    top: 80px;
  }

  .header .MuiPaper-elevation3 {
    box-shadow: none;
  }

  .side-bar {
    display: none;
  }

  .home-right-bar {
    display: none;
  }

  .poll-arrow {
    display: none;
  }

  .input-card {
    margin-top: 50px;
  }

  .modal-content {
    top: 70px;
  }

  .top-margin {
    margin-top: 70px;
  }

  #home-center-wrapper .notice-card {
    display: block;
  }

  #home-center-wrapper .poll-card {
    display: block;
  }

  #home-center-wrapper .demo {
    display: block;
  }
}

.center-dot {
  vertical-align: middle; 
  margin: 0 5px;
}